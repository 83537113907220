import { Controller } from "@hotwired/stimulus";

const isTestEnvironment = 'CapybaraLockstep' in window;

export default class TinymceController extends Controller {
  static targets = [
    "editor",
    "content"
  ]

  tinymce = null;

  async editorTargetConnected(element) {
    if (element.classList.contains('ql-container')) return;

    this.tinymce = (await tinymce.init({
      target: element,
      language: isTestEnvironment ? undefined : 'nb_NO',
      branding: false,
      promotion: false,
      statusbar: false,
      plugins: [
        'lists',
        'link',
        'table',
        'advlist',
        'autoresize',
        'autolink',
        'wordcount'
      ],
      toolbar: 'undo redo | bold italic underline | numlist bullist | table | link',
      // TODO: Tried using RAILS_ASSET_URL to load with `content_css:`, but it's not working
      content_style: `
        table {
          border-collapse: collapse;
          border: 1px solid var(--Neutrals-RK-gray-20, #CDD0D0);
        }

        table th, table td {
          border: 1px solid var(--Neutrals-RK-gray-20, #CDD0D0);
          padding: 0.25rem;
          vertical-align: top;
        }
      `
    }))[0]
  }

  contentTargetConnected(element) {
    this.getForm().addEventListener('submit', (event) => {
      element.value = this.tinymce?.getContent()
    })
  }

  getForm() {
    return this.element;
  }
}
